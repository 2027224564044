import { ApiRequestResponse } from "request/types";
import { HttpMethod, ModelId } from "@laba/ts-common";
import { NotificationActionCode, ReceiverType } from "model/communication";
import { getInternalApiUrl, request } from "request/nexupRequest";
import { AppointmentStatus, ScheduleActionWindow } from "model/resource";

const appointmentBasePath = "/appointment";
export interface InternalAppointmentNotificationDataParams {
  id?: string;
  appointmentId?: string;
  notificationActionCode?: NotificationActionCode;
  receiverType?: ReceiverType;
  patientId?: string;
  practitionerId?: string;
}

export interface AppointmentNotificationData {
  id?: string;
  messageTitle?: string;
  messageBody?: string;
  sessionInitMessage?: string;
  receiver?: string;
  senderId?: string;
  status?: AppointmentStatus;
}

export const getInternalAppointmentNotificationData = async (
  params?: InternalAppointmentNotificationDataParams[]
): Promise<ApiRequestResponse<AppointmentNotificationData[]>> => {
  return request({
    method: HttpMethod.GET,
    url: getInternalApiUrl(`${appointmentBasePath}/notification-data`),
    data: params
  });
};

export interface AppointmentPatientActionData {
  confirmationWindow: ScheduleActionWindow;
  cancellationWindow: ScheduleActionWindow;
}

export const getInternalAppointmentPatientActionData = async (
  id: ModelId
): Promise<ApiRequestResponse<AppointmentPatientActionData>> => {
  return request({
    method: HttpMethod.GET,
    url: getInternalApiUrl(
      `${appointmentBasePath}/patient-appointment-action-data/${id}`
    )
  });
};
