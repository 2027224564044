import { ApiDate, HttpMethod, getKeyObj } from "@laba/ts-common";
import { ModelId, OpenCode } from "model/primitives/model/model";
import { ModelReference } from "model/primitives/modelReference/modelReference";
import {
  AppointmentCancellationReasonCode,
  AppointmentStatus
} from "model/resource/appointment/appointment";
import { AppointmentWithExtraData } from "model/resource/appointment/appointmentWithExtraData";
import { Code } from "model/resource/entities/codeSystem";
import { Location } from "model/resource/entities/location/location";
import { KnownScheduleType, Schedule } from "model/resource/schedule/schedule";
import { getFrontApiUrl, request } from "request/nexupRequest";
import {
  ApiPageRequestResponse,
  ApiRequestResponse,
  ListQueryParams
} from "request/types";
import {
  appointmentBasePath,
  AppointmentListOrder,
  AppointmentStatusAction
} from "api/appointment/appointment";
import { createHydratedMock } from "ts-auto-mock";

export enum AppointmentEncounterStatus {
  Pending = "Pending",
  Booked = "Booked",
  Admitted = "Admitted",
  InProgress = "InProgress",
  Finished = "Finished",
  Cancelled = "Cancelled"
}

export interface UpdateAppointmentStatusData {
  cancellationReason?: string;
  editSeries: boolean;
}

export const UpdateAppointmentStatusDataKey =
  getKeyObj<UpdateAppointmentStatusData>(
    createHydratedMock<UpdateAppointmentStatusData>()
  );

export interface AppointmentWithExtraDataDetailQueryParams {
  withEncounter?: boolean;
  withLocation?: boolean;
  withOriginatingAppointment?: boolean;
  withPatient?: boolean;
  withPractitioner?: boolean;
  withSchedule?: boolean;
  withPayer?: boolean;
  withProduct?: boolean;
  withDefinition?: boolean;
}

export interface AppointmentWithExtraDataListQueryParams
  extends ListQueryParams,
    AppointmentWithExtraDataDetailQueryParams {
  endStartDate?: ApiDate;
  location?: ModelReference<Location>;
  order?: AppointmentListOrder;
  organization?: ModelId;
  patient?: ModelId;
  practitioner?: ModelId;
  recurrenceSeriesId?: ModelId;
  schedule?: ModelReference<Schedule>;
  speciality?: Code;
  startStartDate?: ApiDate;
  status?: AppointmentStatus;
  type?: KnownScheduleType;
  appointmentEncounterStatus?: AppointmentEncounterStatus[];
  tag?: string[];
}

const appointmentWithExtraDataBasePath = "/extra";

export const getAppointmentWithExtraDataList = (
  params?: AppointmentWithExtraDataListQueryParams
): Promise<ApiPageRequestResponse<AppointmentWithExtraData>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(
      `${appointmentBasePath}${appointmentWithExtraDataBasePath}`
    ),
    params
  });

export const getAppointmentWithExtraData = (
  id: ModelId,
  params: AppointmentWithExtraDataDetailQueryParams
): Promise<ApiRequestResponse<AppointmentWithExtraData>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(
      `${appointmentBasePath}/${id}${appointmentWithExtraDataBasePath}`
    ),
    params
  });

export const updateAppointmentStatus = (
  id: ModelId,
  action: AppointmentStatusAction,
  cancellationReason?: OpenCode<AppointmentCancellationReasonCode>
): Promise<ApiRequestResponse<AppointmentWithExtraData>> =>
  request({
    method: HttpMethod.POST,
    url: getFrontApiUrl(`${appointmentBasePath}/${id}/status-update`),
    data: { action, cancellationReason }
  });
