import PhoneInputInt, { CountryData } from "react-phone-input-2";
import React, { useCallback, useEffect, useState } from "react";
import { FC, getClassName } from "@laba/react-common";
import {
  CountryAreaPhoneCode,
  fixPhoneNumberOrUndefined,
  getCountryAreaPhoneCodeFromString,
  getCountryPhonePrefix,
  isArgentinianPhone,
  notUndefined,
  Optional,
  removeArgentinianPhoneInternalAreaCode,
  sleep
} from "@laba/ts-common";
import { isEmpty, startsWith } from "lodash-es";
import { ErrorText } from "components/text/ErrorText/ErrorText";
import { usePhoneInputStyles } from "./usePhoneInputStyles";

type CountryDataOrEmpty = CountryData | unknown;
const isCountryData = (data: CountryDataOrEmpty): data is CountryData => {
  return (data as unknown as Optional<CountryData>)?.countryCode !== undefined;
};

export interface PhoneInputProps {
  className?: string;
  value?: string;
  onChange?: (value?: string) => void;
  defaultCountry?: string;
  disabled?: boolean;
  errorText?: string;
  fullWidth?: boolean;
  onBlur?: (event?: React.FocusEvent<HTMLElement> | undefined) => void;
  excludeCountries?: string[];
  preferredCountries?: string[];
  label?: string;
  placeholder?: string;
  countryCodeEditable?: boolean;
}

const fixCountryCode = (countryCode?: string) => countryCode?.toLowerCase();
const fixCountryCodeList = (countryCodeList?: string[]) =>
  countryCodeList?.map(fixCountryCode)?.filter(notUndefined);

const phoneWithPlusPrefix = (value: string) =>
  isEmpty(value) ? undefined : startsWith(value, "+") ? value : `+${value}`;

const removeArgentinianInternalCode = (value: string) => {
  if (isArgentinianPhone(value)) {
    return removeArgentinianPhoneInternalAreaCode(value);
  }
  return value;
};

const sanitizePhoneString = (value?: string, defaultCountry?: string) => {
  if (value === undefined || isEmpty(value) || value === "+") return undefined;
  const valueWithoutInternalCode = removeArgentinianInternalCode(value);
  const fixed = fixPhoneNumberOrUndefined(
    valueWithoutInternalCode,
    getCountryAreaPhoneCodeFromString(defaultCountry),
    true
  );
  return fixed ?? phoneWithPlusPrefix(valueWithoutInternalCode);
};

const masks = {
  ar: "..........",
  uy: ".........."
};
// to use this input you need to this css import in your final project import "react-phone-input-2/lib/material.css";
export const PhoneInput: FC<PhoneInputProps> = ({
  className,
  value,
  defaultCountry = CountryAreaPhoneCode.Argentina,
  onChange,
  disabled = false,
  errorText,
  onBlur,
  fullWidth = false,
  excludeCountries,
  preferredCountries,
  placeholder,
  countryCodeEditable = false
  // TO-DO: HIS-13419-fix-phone-input-label
  // label
}) => {
  const classes = usePhoneInputStyles({ fullWidth, disabled });
  const defaultCountryPrefix = getCountryPhonePrefix(
    getCountryAreaPhoneCodeFromString(defaultCountry)
  );
  const defaultPlaceholder = defaultCountryPrefix
    ? `+${defaultCountryPrefix}`
    : undefined;
  const [countryCode, setCountryCode] = useState(defaultCountry);

  const wrappedOnChange = useCallback(
    (phoneValue?: string, countryData?: CountryData) => {
      const sanitizedPhone = sanitizePhoneString(
        phoneValue,
        countryData?.countryCode ?? defaultCountry
      );
      onChange?.(sanitizedPhone);
      setCountryCode(countryData?.countryCode ?? defaultCountry);
    },
    [onChange, defaultCountry, setCountryCode]
  );
  const fn = useCallback(async () => {
    // This sleep is needed because there is a race condition between the input and the initialization of the form
    // When this sleep is not present, the form has the correct value, but the metadata of the form is invalid (the
    // 'modified' key of the form state is not consistent with the value changed)
    await sleep(1);
    const sanitized = sanitizePhoneString(value, countryCode);
    if (value !== undefined && sanitized !== phoneWithPlusPrefix(value)) {
      onChange?.(sanitized);
    }
  }, [onChange, value, countryCode]);
  useEffect(() => {
    fn();
  }, [fn]);

  const sanitizedValue = sanitizePhoneString(value, defaultCountry);
  return (
    <div className={getClassName(className, classes.root)}>
      <PhoneInputInt
        containerClass={classes.phoneInput}
        country={fixCountryCode(defaultCountry)}
        value={sanitizedValue}
        onChange={(newValue: string, data: CountryDataOrEmpty) =>
          wrappedOnChange(newValue, isCountryData(data) ? data : undefined)
        }
        disabled={disabled}
        onBlur={onBlur}
        excludeCountries={fixCountryCodeList(excludeCountries)}
        preferredCountries={fixCountryCodeList(preferredCountries)}
        specialLabel=""
        placeholder={placeholder ?? defaultPlaceholder}
        disableDropdown={disabled}
        disableCountryGuess
        countryCodeEditable={countryCodeEditable}
        jumpCursorToEnd
        masks={masks}
      />
      <ErrorText showError={!isEmpty(errorText)} error={errorText} />
    </div>
  );
};
