import { FC, getClassName, TextVariant } from "@laba/react-common";
import { InfoOutlinedIcon } from "components/icons";
import { UrlLink } from "components/link";
import { OnClickUrlAction } from "components/link/UrlLink/utils";
import React from "react";
import { Noop } from "@laba/ts-common";
import { NexupMarkdown } from "../NexupMarkdown/NexupMarkdown";
import { useFormWarningInfoFieldStyles } from "./FormWarningInfoFieldStyle";

export interface FormWarningInfoFieldProps {
  className?: string;
  text?: string;
  onClick?: Noop;
  buttonText?: string;
  showButtonText?: boolean;
  multiline?: boolean;
  isMobile?: boolean;
}

export const FormWarningInfoField: FC<FormWarningInfoFieldProps> = ({
  className,
  text,
  onClick,
  buttonText,
  showButtonText,
  multiline = false,
  isMobile
}) => {
  const classes = useFormWarningInfoFieldStyles({ isMobile });
  return (
    <div className={getClassName(className, classes.root)}>
      <InfoOutlinedIcon className={classes.icon} />
      <div className={classes.textAndLinkWrapper}>
        <NexupMarkdown
          className={getClassName(
            classes.text,
            isMobile
              ? classes.multilineText
              : multiline
              ? classes.multilineText
              : classes.singleLineText
          )}
          defaultVariant={TextVariant.Body2}
          text={text}
          title={text}
        />
        {showButtonText && (
          <UrlLink
            className={classes.urlLink}
            displayText={buttonText}
            onClickUrlAction={OnClickUrlAction.CustomClick}
            onClick={onClick}
          />
        )}
      </div>
    </div>
  );
};
