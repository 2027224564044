import React from "react";
import {
  FCC,
  getClassName,
  TextVariant,
  ThemeStyleColorVariant
} from "@laba/react-common";
import { isEmpty } from "lodash-es";
import { UrlLink, UrlLinkProps } from "components/link";
import { TitleContentData } from "components/text";
import { useLinkFieldDetailStyles } from "./useLinkFieldDetailStyles";

export interface LinkFieldDetailProps extends UrlLinkProps {
  title?: string;
  className?: string;
  titleVariant?: TextVariant;
  titleColorVariant?: ThemeStyleColorVariant;
  gap?: number;
  hide?: boolean;
}

export const LinkFieldDetail: FCC<LinkFieldDetailProps> = ({
  title,
  className,
  titleVariant,
  titleColorVariant = ThemeStyleColorVariant.GrayDark,
  hide = false,
  gap,
  displayText,
  url,
  ...rest
}) => {
  const classes = useLinkFieldDetailStyles({
    gap
  });

  return !hide ? (
    <div className={getClassName(className, classes.root)}>
      <TitleContentData
        titleVariant={titleVariant}
        title={title}
        titleColorVariant={titleColorVariant}
        hideTextIfEmpty={!isEmpty(displayText)}
      />
      <UrlLink displayText={displayText} url={url} {...rest} />
    </div>
  ) : null;
};
