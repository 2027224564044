import {
  createStyle,
  FlexAlignItems,
  FlexAlignSelf,
  flexColumnMixin,
  flexItemMixin,
  flexRowMixin,
  paddingMixin,
  pxToRem,
  StyleVariant,
  TextVariant,
  Theme,
  ThemeStyleColorVariant
} from "@laba/react-common";
import {
  getThemeStyleColor,
  getThemeStyleState,
  themeTextMixin
} from "model/themeUtils";

interface FormWarningInfoFieldDynamicStylesProps {
  isMobile?: boolean;
}

interface FormWarningInfoFieldClassNames {
  icon: string;
  text: string;
  singleLineText: string;
  multilineText: string;
  urlLink: string;
  root: string;
  textAndLinkWrapper: string;
}

const useFormWarningInfoFieldStaticStyles = createStyle(
  (theme: Theme) => ({
    icon: {
      ...flexItemMixin({ align: FlexAlignSelf.FlexStart, shrink: 0 }),
      color: getThemeStyleColor(theme, StyleVariant.Warning)
    },
    text: {
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    },

    singleLineText: { ...themeTextMixin(theme, TextVariant.Body2, 1) },
    multilineText: { ...themeTextMixin(theme, TextVariant.Body2) },
    urlLink: { minWidth: "min-content" }
  }),
  "FormWarningInfoField"
);

const useFormWarningInfoFieldDynamicStyles = createStyle(
  (theme: Theme) => ({
    root: (props: FormWarningInfoFieldDynamicStylesProps) => ({
      ...paddingMixin({ leftRight: props.isMobile ? 8 : 16, topBottom: 8 }),
      ...flexRowMixin({ gap: 8, alignItems: FlexAlignItems.Center }),
      minHeight: pxToRem(40),
      width: "100%",
      backgroundColor: getThemeStyleState(theme, StyleVariant.Warning).hover
    }),
    textAndLinkWrapper: (props: FormWarningInfoFieldDynamicStylesProps) => ({
      ...(props.isMobile
        ? flexColumnMixin({ gap: 4 })
        : flexRowMixin({ gap: 4 }))
    })
  }),
  "FormWarningInfoFieldDynamic"
);

export const useFormWarningInfoFieldStyles = (
  props: FormWarningInfoFieldDynamicStylesProps
): FormWarningInfoFieldClassNames => {
  const staticClasses = useFormWarningInfoFieldStaticStyles();
  const dynamicClasses = useFormWarningInfoFieldDynamicStyles(props);

  return {
    icon: staticClasses.icon,
    text: staticClasses.text,
    singleLineText: staticClasses.singleLineText,
    multilineText: staticClasses.multilineText,
    urlLink: staticClasses.urlLink,
    root: dynamicClasses.root,
    textAndLinkWrapper: dynamicClasses.textAndLinkWrapper
  };
};
