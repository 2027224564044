import { MeasurementGroupDefinition } from "model/resource/medical/observation/measurementGroupDefinition";
import { ModelReference } from "model/primitives/modelReference/modelReference";
import { AppointmentDefinition } from "model/resource/appointment/appointmentDefinition";
import { ProcedureReportDefinition } from "model/resource/procedures/procedureReportDefinition/procedureReportDefinition";
import { OpenCode } from "model/primitives/model/model";
import { Workspace } from "model/resource/app/workspace";
import { Dashboard as ApiDashboard } from "model/resource/entities/organization";
import { WhiteLabelConfig } from "model/resource/app/whiteLabelConfig";
import { Organization } from "../entities/organization/organization";
import { Questionnaire } from "../evolution/questionnaire/questionnaire";
import { HealthcareService } from "../entities/healthcareService/healthcareService";
import { MedicalRequestDefinition } from "../prescription/medicalRequestDefinition/medicalRequestDefinition";
import { PatientPermissionConfig } from "./permissionConfig";
import { ScheduleDefinition } from "../schedule/scheduleDefinition";

export type AppConfigFeatureFlag = boolean;

export enum MedicalAppConfigPermissionCode {
  Admin = "admin",
  Practitioner = "practitioners",
  PractitionersList = "practitioners_list",
  PractitionersCreatePermanent = "practitioners_create_permanent",
  PractitionersCreateTemporal = "practitioners_create_temporal",
  PractitionersManageUser = "practitioners_manage_user",
  PractitionersManageRoles = "practitioners_manage_roles",
  PractitionersView = "practitioners_view",
  PractitionersEdit = "practitioners_edit",
  Patients = "patients",
  PatientsList = "patients_list",
  PatientsCreatePermanent = "patients_create_permanent",
  PatientsCreateTemporal = "patients_create_temporal",
  PatientsView = "patients_view",
  PatientsEdit = "patients_edit",
  ClinicHistory = "clinic_history",
  ClinicHistoryList = "clinic_history_list",
  ClinicHistoryMedicalRequest = "clinic_history_medical_request",
  ClinicHistoryEvolution = "clinic_history_evolution",
  ClinicHistoryCreateMedicalRequest = "clinic_history_create_medical_request",
  ClinicHistoryEditMedicalRequest = "clinic_history_edit_medical_request",
  ClinicHistoryViewMedicalRequest = "clinic_history_view_medical_request",
  ClinicHistoryRenewMedicalRequest = "clinic_history_renew_medical_request",
  ClinicHistoryCancelMedicalRequest = "clinic_history_cancel_medical_request",
  ClinicHistoryEditProfile = "clinic_history_edit_profile",
  ClinicHistoryCreateQuestionnaireResponse = "clinic_history_create_questionnaire_response",
  ClinicHistoryEditQuestionnaireResponse = "clinic_history_edit_questionnaire_response",
  ClinicHistoryViewQuestionnaireResponse = "clinic_history_view_questionnaire_response",
  ClinicHistoryCancelQuestionnaireResponse = "clinic_history_cancel_questionnaire_response",
  ClinicHistoryQuestionnaireResponseConsumptionCreateProduct = "clinic_history_questionnaire_response_consumption_create_product",
  ClinicHistoryPrescriptionRequest = "clinic_history_prescription_request",
  ClinicHistoryCreatePrescriptionRequest = "clinic_history_create_prescription_request",
  ClinicHistoryGenerateEvolutionReport = "clinic_history_generate_evolution_report",
  ClinicHistoryGenerateMedicalRequestReport = "clinic_history_generate_medical_request_report",
  ClinicHistoryGenerateMedicalRequestNursingReport = "clinic_history_generate_medical_request_nursing_report",
  ClinicHistoryProcedureReport = "clinic_history_procedure_report",
  ClinicHistoryCreateProcedureReport = "clinic_history_create_procedure_report",
  ClinicHistoryEditProcedureReport = "clinic_history_edit_procedure_report",
  ClinicHistoryEditProcedureReportCode = "clinic_history_edit_procedure_report_code",
  ClinicHistoryViewProcedureReport = "clinic_history_view_procedure_report",
  ClinicHistoryCancelProcedureReport = "clinic_history_cancel_procedure_report",
  ClinicHistoryCreateSimplePatient = "clinic_history_create_simple_patient",
  ClinicHistoryCreatePatient = "clinic_history_create_patient",
  ClinicHistoryBillingConsumption = "clinic_history_billing_consumption",
  ClinicHistoryBillingConsumptionCreate = "clinic_history_billing_consumption_create",
  ClinicHistoryBillingConsumptionEdit = "clinic_history_billing_consumption_edit",
  ClinicHistoryBillingConsumptionCancel = "clinic_history_billing_consumption_cancel",
  ClinicHistoryBillingConsumptionExport = "clinic_history_billing_consumption_export",
  Nursing = "nursing",
  NursingPatientList = "nursing_patient_list",
  NursingPatientDetail = "nursing_patient_detail",
  NursingAdministration = "nursing_administration",
  NursingVitalSigns = "nursing_vital_signs",
  NursingFluidBalance = "nursing_fluid_balance",
  NursingCreateMedicalAdministration = "nursing_create_medical_administration",
  NursingEditMedicalAdministration = "nursing_edit_medical_administration",
  NursingViewMedicalAdministration = "nursing_view_medical_administration",
  NursingGenerateReport = "nursing_generate_report",
  NursingCreateVitalSigns = "nursing_create_vital_signs",
  NursingEditVitalSigns = "nursing_edit_vital_signs",
  NursingViewVitalSigns = "nursing_view_vital_signs",
  NursingCancelVitalSigns = "nursing_cancel_vital_signs",
  NursingCreateFluidBalance = "nursing_create_fluid_balance",
  NursingEditFluidBalance = "nursing_edit_fluid_balance",
  NursingViewFluidBalance = "nursing_view_fluid_balance",
  NursingCancelFluidBalance = "nursing_cancel_fluid_balance",
  NursingEvolution = "nursing_evolution",
  NursingCreateQuestionnaireResponse = "nursing_create_questionnaire_response",
  NursingEditQuestionnaireResponse = "nursing_edit_questionnaire_response",
  NursingViewQuestionnaireResponse = "nursing_view_questionnaire_response",
  NursingCancelQuestionnaireResponse = "nursing_cancel_questionnaire_response",
  NursingQuestionnaireResponseConsumptionCreateProduct = "nursing_questionnaire_response_consumption_create_product",
  VirtualGuard = "virtual_guard",
  VirtualGuardEncounterList = "virtual_guard_encounter_list",
  VirtualGuardEncounterDetail = "virtual_guard_encounter_detail",
  VirtualGuardCancelEncounter = "virtual_guard_cancel_encounter",
  VirtualGuardTriageEncounter = "virtual_guard_triage_encounter",
  Appointment = "appointment",
  AppointmentList = "appointment_list",
  AppointmentDetail = "appointment_detail",
  AppointmentCreate = "appointment_create",
  AppointmentEdit = "appointment_edit",
  AppointmentConfirm = "appointment_confirm",
  AppointmentAdmit = "appointment_admit",
  AppointmentAttend = "appointment_attend",
  AppointmentCancel = "appointment_cancel",
  AppointmentFulfill = "appointment_fulfill",
  AppointmentShare = "appointment_share",
  AppointmentPractitionerList = "appointment_practitioner_list",
  AppointmentScheduleList = "appointment_schedule_list",
  AppointmentScheduleDetail = "appointment_schedule_detail",
  AppointmentScheduleCreate = "appointment_schedule_create",
  AppointmentScheduleEdit = "appointment_schedule_edit",
  AppointmentScheduleCancel = "appointment_schedule_cancel",
  AppointmentScheduleCreateVirtual = "appointment_schedule_create_virtual",
  AppointmentCalendar = "appointment_calendar",
  AppointmentAdmission = "appointment_admission",
  AppointmentCreateSimplePatient = "appointment_create_simple_patient",
  AppointmentEditPatientContactData = "appointment_edit_patient_contact_data",
  AppointmentNotificationConfigView = "appointment_notification_config_view",
  AppointmentNotificationConfigEdit = "appointment_notification_config_edit",
  AppointmentBillingProducts = "appointment_billing_products",
  AppointmentScheduleBillingProducts = "appointment_schedule_billing_products",
  AppointmentBillingCreateProduct = "appointment_billing_create_product",
  AppointmentScheduleBillingCreateProduct = "appointment_schedule_billing_create_product",
  AppointmentScheduleConfirmConfig = "appointment_schedule_confirm_config",
  AppointmentScheduleCancelConfig = "appointment_schedule_cancel_config",
  AppointmentEditLocation = "appointment_edit_location",
  AppointmentEditPractitioner = "appointment_edit_practitioner",
  Management = "management",
  ManagementProcedureList = "management_procedure_list",
  ManagementProcedureDetail = "management_procedure_detail",
  ManagementProcedureEdit = "management_procedure_edit",
  ManagementProcedureCancel = "management_procedure_cancel",
  Dashboard = "dashboard",
  DashboardList = "dashboard_list",
  DashboardAll = "dashboard_all",
  CodeSystemAddProcedureCode = "code_system_add_procedure_code",
  CodeSystemAddProcedureTag = "code_system_add_procedure_tag",
  CodeSystemAddAppointmentTag = "code_system_add_appointment_tag",
  CodeSystemAddScheduleSpeciality = "code_system_add_schedule_speciality",
  CodeSystemAddPatientTag = "code_system_add_patient_tag",
  CodeSystemAddMedicalRequestConditionCode = "code_system_add_medical_request_condition_code",
  CodeSystemAddProductSubcategory = "code_system_add_product_subcategory",
  CodeSystemAddProductTag = "code_system_add_product_tag",
  CodeSystemAddScheduleDuration = "code_system_add_schedule_duration",
  CodeSystemAddPatientPayerPlan = "code_system_add_patient_payer_plan",
  CodeSystemAddEvolutionOdontogramDiagnostic = "code_system_add_evolution_odontogram_diagnostic",
  CodeSystemAddHospitalizationAdmitSource = "code_system_add_hospitalization_admit_source",
  CodeSystemAddHospitalizationTag = "code_system_add_hospitalization_tag",

  Billing = "billing",
  BillingProductList = "billing_product_list",
  BillingProductCreate = "billing_product_create",
  BillingProductEdit = "billing_product_edit",
  BillingProductExport = "billing_product_export",
  BillingProductImport = "billing_product_import",
  BillingConsumptionAutoCompleteCoverage = "billing_consumption_autocomplete_coverage",
  BillingConsumptionList = "billing_consumption_list",
  BillingConsumptionCreate = "billing_consumption_create",
  BillingConsumptionEdit = "billing_consumption_edit",
  BillingConsumptionCancel = "billing_consumption_cancel",
  BillingConsumptionExport = "billing_consumption_export",
  BillingConsumptionPractitionerList = "billing_consumption_practitioner_list",
  BillingConsumptionCreateProduct = "billing_consumption_create_product",
  BillingConsumptionBreakdownItem = "billing_consumption_breakdown_item",
  BillingConsumptionEditPriceFromBillingContract = "billing_consumption_edit_price_from_billing_contract",
  BillingInvoiceList = "billing_invoice_list",
  BillingInvoiceCreate = "billing_invoice_create",
  BillingInvoiceCreateForPayer = "billing_invoice_create_for_payer",
  BillingInvoiceCreateForPatient = "billing_invoice_create_for_patient",
  BillingInvoiceEdit = "billing_invoice_edit",
  BillingInvoiceExport = "billing_invoice_export",
  BillingInvoiceEditConsumption = "billing_invoice_edit_consumption",
  BillingInvoiceRemoveConsumption = "billing_invoice_remove_consumption",
  BillingInvoiceAddConsumption = "billing_invoice_add_consumption",
  BillingInvoiceSendFile = "billing_invoice_send_file",
  BillingInvoiceCancel = "billing_invoice_cancel",
  BillingContractCreate = "billing_contract_create",
  BillingContractEdit = "billing_contract_edit",
  BillingContractCopy = "billing_contract_copy",
  BillingContractList = "billing_contract_list",
  BillingContractCancel = "billing_contract_cancel",
  BillingContractCreateTerm = "billing_contract_create_term",
  BillingContractEditTerm = "billing_contract_edit_term",
  BillingContractDeleteTerm = "billing_contract_delete_term",
  BillingContractConfigureConsumptionRecalculation = "billing_contract_configure_consumption_recalculation",
  BillingContractExport = "billing_contract_export",
  BillingContractImport = "billing_contract_import",
  BillingConsumptionAddProductOutsideBillingContract = "billing_consumption_add_product_outside_billing_contract",
  BillingConsumptionEditPatientData = "billing_consumption_edit_patient_data",

  ProfileWorkspaceBillingNotifications = "profile_workspace_billing_notifications",
  ProfileCreateWorkspace = "profile_create_workspace",
  ProfilePractitionerEdit = "profile_practitioner_edit",
  ProfileWorkspaceEditAvailability = "profile_workspace_edit_availability",
  ProfileWorkspaceInvite = "profile_workspace_invite",
  ProfileWorkspaceBillingDetail = "profile_workspace_billing_detail",
  ProfileWorkspaceBillingConfig = "profile_workspace_billing_config",
  ProfileWorkspaceEdit = "profile_workspace_edit",
  ProfileDetail = "profile_detail",

  Hospitalization = "hospitalization",
  HospitalizationList = "hospitalization_list",
  HospitalizationCreate = "hospitalization_create",
  HospitalizationEdit = "hospitalization_edit",
  HospitalizationPendingCancel = "hospitalization_pending_cancel",
  HospitalizationInProgressCancel = "hospitalization_in_progress_cancel",
  HospitalizationDischargedCancel = "hospitalization_discharged_cancel",
  HospitalizationCreateSimplePatient = "hospitalization_create_simple_patient",
  HospitalizationEditTag = "hospitalization_edit_tag",
  HospitalizationBedList = "hospitalization_bed_list",
  HospitalizationBedChangeBed = "hospitalization_bed_change_bed",
  HospitalizationBedBook = "hospitalization_bed_book",
  HospitalizationBedAdmit = "hospitalization_bed_admit",
  HospitalizationBedDischarge = "hospitalization_bed_discharge",
  HospitalizationBedCreateWristband = "hospitalization_bed_create_wristband",
  HospitalizationEditBuilding = "hospitalization_edit_building",
  HospitalizationHospitalizedBedOptional = "hospitalization_hospitalized_bed_optional"
}

export enum PatientAppConfigPermissionCode {
  Admin = "admin",
  VirtualGuard = "virtual_guard",
  Profile = "profile",
  Home = "home",
  Directory = "directory",
  Appointment = "appointment",
  AppointmentConfirm = "appointment_confirm",
  AppointmentCancel = "appointment_cancel"
}

export const hasCodeMedicalAppConfigPermissionList = (
  permission: OpenCode<MedicalAppConfigPermissionCode>,
  permissionList?: MedicalAppConfigPermissionCode[]
): boolean =>
  permissionList?.some(
    _permission =>
      _permission === permission ||
      _permission === MedicalAppConfigPermissionCode.Admin
  ) ?? false;

export const hasCodePatientAppConfigPermissionList = (
  permission: PatientAppConfigPermissionCode,
  permissionList?: PatientAppConfigPermissionCode[]
): boolean =>
  permissionList?.some(
    _permission =>
      _permission === permission ||
      _permission === PatientAppConfigPermissionCode.Admin
  ) ?? false;

export interface AppConfig extends Workspace {
  medicalRequestDefinitionList: MedicalRequestDefinition[];
  questionnaireList: Questionnaire[];
  serviceList: HealthcareService[];
  appointmentDefinitionList: AppointmentDefinition[];
  measurementGroupDefinitionList: MeasurementGroupDefinition[];
  procedureReportDefinitionList: ProcedureReportDefinition[];
  scheduleDefinitionList: ScheduleDefinition[];
  country?: string;
  timezoneIana?: string;
  dashboardConfig?: ApiDashboard[];
  whiteLabelConfig?: WhiteLabelConfig;
}

export interface PatientAppConfig extends PatientPermissionConfig {
  organizationData?: {
    organization: ModelReference<Organization>;
    serviceList: HealthcareService[];
  };
}
