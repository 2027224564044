import { createStyle, flexColumnMixin, Theme } from "@laba/react-common";

interface Props {
  gap?: number;
}

export const useLinkFieldDetailStyles = createStyle(
  (_theme: Theme) => ({
    root: (props: Props) => ({
      ...flexColumnMixin({ gap: props.gap ?? 4 })
    })
  }),
  "LinkFieldDetailStyles"
);
