import { getModelOrUndefined } from "model/primitives/modelReference/utils";
import { head, isEmpty, isUndefined } from "lodash-es";
import {
  DateTime,
  ApiDate,
  applyFormatToDate,
  DateFormat,
  getCurrentDateTime,
  getEnumOrUndefined,
  Optional,
  dateIsBefore,
  dateIsAfter,
  MinVirtualInfiniteDateTime,
  MaxVirtualInfiniteDateTime,
  dateTimeIsBetweenDateTimes
} from "@laba/ts-common";
import { AppointmentEncounterStatus } from "api/appointment/appointmentWithExtraData";
import { EncounterStatus } from "model/resource/entities/encounter/encounter";
import {
  getScheduleOrganization,
  KnownScheduleType,
  Schedule,
  ScheduleActionWindow
} from "model/resource/schedule";
import {
  Address,
  ContactPoint,
  ContactPointRole,
  getAddressFullText
} from "model/primitives";
import {
  Appointment,
  AppointmentRecurrenceTemplate,
  AppointmentStatus
} from "./appointment";
import { AppointmentWithExtraData } from "./appointmentWithExtraData";
import {
  getCredentialNumberFromPatient,
  getHealthcarePlanFromPatient
} from "../person";
import { Location, Organization } from "../entities";
import { isScheduleActionWindowNotUndefined } from "../schedule/helpers";

export const isAppointmentBooked = (appointment?: Appointment): boolean =>
  appointment?.status === AppointmentStatus.Booked;

export const isAppointmentCancelled = (appointment?: Appointment): boolean =>
  appointment?.status === AppointmentStatus.Cancelled;

export const isAppointmentFulfilled = (appointment?: Appointment): boolean =>
  appointment?.status === AppointmentStatus.Fulfilled;

export const isAppointmentPending = (appointment?: Appointment): boolean =>
  appointment?.status === AppointmentStatus.Pending;

export const isAppointmentVirtual = (appointment?: Appointment): boolean => {
  return appointment?.type === KnownScheduleType.VideoChat;
};

export const isVideoChatAppointment = (
  appointmentWithExtraData: AppointmentWithExtraData
): boolean => {
  return isAppointmentVirtual(appointmentWithExtraData.appointment);
};

export const getFirstAppointmentSpeciality = (
  appointment?: Appointment
): Optional<string> => {
  return head(appointment?.speciality);
};

export const getAppointmentEncounterStatus = (
  appointmentWithExtraData?: AppointmentWithExtraData
): Optional<AppointmentEncounterStatus> => {
  const appointmentStatus = appointmentWithExtraData?.appointment?.status;
  const encounterStatus = getModelOrUndefined(
    appointmentWithExtraData?.encounter
  )?.status;
  if (
    encounterStatus === EncounterStatus.Arrived ||
    appointmentStatus === AppointmentStatus.Arrived
  )
    return AppointmentEncounterStatus.Admitted;
  if (
    encounterStatus === EncounterStatus.InProgress ||
    encounterStatus === EncounterStatus.Triaged
  )
    return AppointmentEncounterStatus.InProgress;
  if (
    appointmentStatus === AppointmentStatus.Pending &&
    encounterStatus === undefined
  )
    return AppointmentEncounterStatus.Pending;
  if (
    appointmentStatus === AppointmentStatus.Booked &&
    encounterStatus === undefined
  )
    return AppointmentEncounterStatus.Booked;
  if (encounterStatus === EncounterStatus.Finished)
    return AppointmentEncounterStatus.Finished;
  if (appointmentStatus === AppointmentStatus.Cancelled)
    return AppointmentEncounterStatus.Cancelled;
  if (appointmentStatus === AppointmentStatus.Pending)
    return AppointmentEncounterStatus.Pending;

  return AppointmentEncounterStatus.Cancelled;
};

interface AppointmentRecurrenceData extends AppointmentRecurrenceTemplate {
  firstOccurrenceDate?: ApiDate;
}

const getRecurrenceTemplateInfo = (
  appointment?: Appointment
): AppointmentRecurrenceData => {
  const recurrenceTemplate = appointment?.recurrenceTemplate;
  const firstOccurrenceDate = applyFormatToDate(
    appointment?.startDate,
    DateFormat.Spanish
  );
  const lastOccurrenceDate = applyFormatToDate(
    recurrenceTemplate?.lastOccurrenceDate,
    DateFormat.Spanish
  );
  return {
    firstOccurrenceDate,
    lastOccurrenceDate,
    weekInterval: recurrenceTemplate?.weekInterval,
    occurrenceCount: recurrenceTemplate?.occurrenceCount
  };
};

export const getAppoinmentRecurrenceInfo = (
  appointment?: Appointment
): Optional<AppointmentRecurrenceData> => {
  const originatingAppointment = getModelOrUndefined(
    appointment?.originatingAppointment
  );
  if (!isUndefined(appointment?.recurrenceTemplate)) {
    return getRecurrenceTemplateInfo(appointment);
  }

  if (!isUndefined(originatingAppointment)) {
    return getRecurrenceTemplateInfo(originatingAppointment);
  }
};

export const getAppoinmentRecurrenceWeekInterval = (
  appointment?: Appointment
): Optional<number> => {
  return getAppoinmentRecurrenceInfo(appointment)?.weekInterval;
};

export const getCalendarUrlFromAppointment = (
  appointment?: Appointment
): Optional<string> => appointment?.patientAccessInfo?.addToCalendarUrl;

const getContactPointFromAppointment = (
  role: ContactPointRole,
  appointment?: Appointment
) =>
  appointment?.notificationChannelList?.find(
    notification => notification.contactPoint?.role === role
  )?.contactPoint;

export const getNotificationEmailObjectFromAppointment = (
  appointment?: Appointment
): Optional<ContactPoint> => {
  return getContactPointFromAppointment(
    ContactPointRole.NotificationEmail,
    appointment
  );
};

export const getNotificationPhoneObjectFromAppointment = (
  appointment?: Appointment
): Optional<ContactPoint> => {
  return getContactPointFromAppointment(
    ContactPointRole.NotificationPhone,
    appointment
  );
};

export const getNotificationEmailFromAppointment = (
  appointment?: Appointment
): Optional<string> => {
  return getNotificationEmailObjectFromAppointment(appointment)?.value;
};

export const getNotificationPhoneFromAppointment = (
  appointment?: Appointment
): Optional<string> => {
  return getNotificationPhoneObjectFromAppointment(appointment)?.value;
};

enum ActionWindowComparisonType {
  Before = "Before",
  After = "After",
  Inside = "Inside"
}

const getAppointmentSchedule = (
  appointment?: Appointment
): Optional<Schedule> => getModelOrUndefined(appointment?.schedule);

const getAppointmentLocation = (
  appointment?: Appointment
): Optional<Location> => getModelOrUndefined(appointment?.location);

const getAppointmentWithExtraDataLocation = (
  appointmentWithExtraData?: AppointmentWithExtraData
): Optional<Location> =>
  getAppointmentLocation(appointmentWithExtraData?.appointment);

const getAppointmentWithExtraDataLocationAddress = (
  appointmentWithExtraData?: AppointmentWithExtraData
): Optional<Address> =>
  getAppointmentWithExtraDataLocation(appointmentWithExtraData)?.address;

export const getAppointmentWithExtraDataLocationAddressFullText = (
  appointmentWithExtraData?: AppointmentWithExtraData
): Optional<string> =>
  getAddressFullText(
    getAppointmentWithExtraDataLocationAddress(appointmentWithExtraData)
  );

const getAppointmentWithExtraDataSchedule = (
  appointmentWithExtraData?: AppointmentWithExtraData
): Optional<Schedule> =>
  getAppointmentSchedule(appointmentWithExtraData?.appointment);

const compareCurrentDateWithAppointmentActionWindow = (
  isConfirmationWindow: boolean,
  comparisonType: ActionWindowComparisonType,
  appointment?: Appointment
): boolean => {
  const schedule = getAppointmentSchedule(appointment);
  const startDate = appointment?.startDate;

  if (!schedule || !startDate) return false;

  const appointmentStartDateTime = DateTime.fromApiDate(startDate);
  const currentDateTime = getCurrentDateTime();

  const windowStart = isConfirmationWindow
    ? schedule.confirmationWindow?.start
    : schedule.cancellationWindow?.start;
  const windowEnd = isConfirmationWindow
    ? schedule.confirmationWindow?.end
    : schedule.cancellationWindow?.end;
  if (
    !isScheduleActionWindowNotUndefined(schedule.confirmationWindow) &&
    isConfirmationWindow
  )
    return false;

  const windowStartDateTime = !isUndefined(windowStart)
    ? appointmentStartDateTime.minus({ hours: windowStart })
    : MinVirtualInfiniteDateTime;
  const windowEndDateTime = !isUndefined(windowEnd)
    ? appointmentStartDateTime.minus({ hours: windowEnd })
    : MaxVirtualInfiniteDateTime;

  switch (comparisonType) {
    case ActionWindowComparisonType.Before:
      return dateIsBefore(currentDateTime, windowStartDateTime);
    case ActionWindowComparisonType.After:
      return dateIsAfter(currentDateTime, windowEndDateTime);
    case ActionWindowComparisonType.Inside: {
      return dateTimeIsBetweenDateTimes(
        currentDateTime,
        windowStartDateTime,
        windowEndDateTime
      );
    }
  }
};

export const isCurrentDateInsideAppointmentActionWindow = (
  isConfirmationWindow: boolean,
  appointment?: Appointment
): boolean =>
  compareCurrentDateWithAppointmentActionWindow(
    isConfirmationWindow,
    ActionWindowComparisonType.Inside,
    appointment
  );

export const isCurrentDateBeforeAppointmentActionWindow = (
  isConfirmationWindow: boolean,
  appointment?: Appointment
): boolean =>
  compareCurrentDateWithAppointmentActionWindow(
    isConfirmationWindow,
    ActionWindowComparisonType.Before,
    appointment
  );

export const isCurrentDateAfterAppointmentActionWindow = (
  isConfirmationWindow: boolean,
  appointment?: Appointment
): boolean =>
  compareCurrentDateWithAppointmentActionWindow(
    isConfirmationWindow,
    ActionWindowComparisonType.After,
    appointment
  );

export const isCurrentDateAfterAppointmentStartDate = (
  appointment?: Appointment
): boolean => {
  const startDate = appointment?.startDate;

  if (!startDate) return false;
  const appointmentStartDateTime = DateTime.fromApiDate(startDate);
  const currentDateTime = getCurrentDateTime();
  return dateIsAfter(currentDateTime, appointmentStartDateTime);
};

export const canCancelAppointment = (appointment?: Appointment): boolean => {
  return (
    !isAppointmentCancelled(appointment) && !isAppointmentFulfilled(appointment)
  );
};
export const isRecurringAppointment = (appointment?: Appointment): boolean => {
  return (
    !isUndefined(appointment?.recurrenceTemplate?.weekInterval) ||
    !isUndefined(appointment?.originatingAppointment)
  );
};

export const getAppointmentType = (
  appointment?: Appointment
): Optional<KnownScheduleType> =>
  getEnumOrUndefined(KnownScheduleType)(appointment?.type);

export interface AppointmentPatientHealthcarePayerData {
  plan?: string;
  credentialNumber?: string;
  payerName?: string;
}
export const getAppointmentPatientHealthcarePayerData = (
  appointment?: Appointment
): AppointmentPatientHealthcarePayerData => {
  const patient = getModelOrUndefined(appointment?.patient);
  const payer = getModelOrUndefined(appointment?.payer);
  const plan = appointment?.plan ?? getHealthcarePlanFromPatient(patient);
  const credentialNumber = getCredentialNumberFromPatient(patient);
  const payerName = payer?.name;
  return payer
    ? { plan, credentialNumber, payerName }
    : { payerName: undefined, credentialNumber: undefined, plan: undefined };
};

export const getAppointmentWithExtraDataScheduleOrganization = (
  appointmentWithExtraData?: AppointmentWithExtraData
): Optional<Organization> => {
  const schedule = getAppointmentWithExtraDataSchedule(
    appointmentWithExtraData
  );
  return getScheduleOrganization(schedule);
};

export const isAppointmentCancellableAnyTime = (
  cancellationWindow?: ScheduleActionWindow
): boolean => {
  return !isScheduleActionWindowNotUndefined(cancellationWindow);
};

export const isAppointmentCancellable = (
  cancellationWindow?: ScheduleActionWindow,
  appointment?: Appointment
): boolean => {
  return (
    canCancelAppointment(appointment) && isUndefined(cancellationWindow?.start)
  );
};

const getAppointmentWithExtraDataScheduleOrganizationAddress = (
  appointmentWithExtraData?: AppointmentWithExtraData
): Address[] =>
  getAppointmentWithExtraDataScheduleOrganization(appointmentWithExtraData)
    ?.address ?? [];

export const getAppointmentWithExtraDataScheduleOrganizationAddressFullText = (
  appointmentWithExtraData?: AppointmentWithExtraData
): Optional<string> => {
  const addresses = getAppointmentWithExtraDataScheduleOrganizationAddress(
    appointmentWithExtraData
  );
  return getAddressFullText(addresses.find(address => !isEmpty(address)));
};
