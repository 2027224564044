import { ResourceType } from "model/primitives/resourceModel";
import { Gender, ValidationStatus } from "model/resource/person/person";
import { LocationStatus } from "model/resource/entities/location/location";
import { EncounterStatus } from "model/resource/entities/encounter/encounter";
import { OrganizationType } from "model/resource/entities";
import { KnownScheduleType } from "model/resource/schedule";
import { ContactPointRole, ContactPointType, Day } from "model/primitives";
import { getCurrentDateAsString } from "@laba/ts-common";
import { AppointmentStatus } from "../appointment";
import { AppointmentWithExtraData } from "../appointmentWithExtraData";
import { bookedAppointmentMock } from "./appointment";

export const appointmentWithExtraDataMock: AppointmentWithExtraData = {
  appointment: bookedAppointmentMock,
  encounter: "1"
};

export const appointmentWithExtraDataListMock: AppointmentWithExtraData[] = [
  {
    appointment: {
      id: "61",
      creationDate: "2022-08-11T00:45:03.113Z",
      location: {
        name: "Springfield",
        resourceType: ResourceType.Location,
        status: LocationStatus.Active
      },
      patient: {
        personalData: {
          gender: Gender.Male,
          firstName: "Sebastaian",
          otherName: "Gonzalo",
          lastName: "Salamandra",
          motherLastName: "Achuras"
        },
        organization: "3",
        resourceType: ResourceType.Patient,
        validationStatus: ValidationStatus.Temporal
      },
      practitionerTeam: [
        {
          practitioner: {
            personalData: {
              gender: Gender.Male,
              firstName: "Sebastaian",
              otherName: "Gonzalo",
              lastName: "Salamandra",
              motherLastName: "Achuras"
            },
            organization: ["3"],
            resourceType: ResourceType.Practitioner,
            validationStatus: ValidationStatus.Temporal
          }
        },
        {
          practitioner: {
            personalData: {
              gender: Gender.Male,
              firstName: "Sebastaian",
              otherName: "Gonzalo",
              lastName: "Salamandra",
              motherLastName: "Achuras"
            },
            organization: ["3"],
            resourceType: ResourceType.Practitioner,
            validationStatus: ValidationStatus.Temporal
          }
        },
        {
          practitioner: {
            personalData: {
              gender: Gender.Male,
              firstName: "Sebastaian",
              otherName: "Gonzalo",
              lastName: "Salamandra",
              motherLastName: "Achuras"
            },
            organization: ["3"],
            resourceType: ResourceType.Practitioner,
            validationStatus: ValidationStatus.Temporal
          }
        }
      ],
      status: AppointmentStatus.Booked,
      resourceType: ResourceType.Appointment
    },
    encounter: {
      callData: { chatId: "243", roomList: [] },
      patient: "234",
      status: EncounterStatus.Arrived,
      resourceType: ResourceType.VideoAppointmentEncounter
    }
  },
  {
    appointment: {
      id: "61",
      creationDate: "2022-08-11T00:45:03.113Z",
      location: {
        name: "Springfield",
        resourceType: ResourceType.Location,
        status: LocationStatus.Active
      },
      patient: {
        personalData: {
          gender: Gender.Male,
          firstName: "Geronimo",
          otherName: "Benicio",
          lastName: "Cuenca",
          motherLastName: "Ramirez"
        },
        organization: "3",
        resourceType: ResourceType.Patient,
        validationStatus: ValidationStatus.Temporal
      },
      practitionerTeam: [
        {
          practitioner: {
            personalData: {
              gender: Gender.Male,
              firstName: "Sebastaian",
              otherName: "Gonzalo",
              lastName: "Salamandra",
              motherLastName: "Achuras"
            },
            organization: ["3"],
            resourceType: ResourceType.Practitioner,
            validationStatus: ValidationStatus.Temporal
          }
        },
        {
          practitioner: {
            personalData: {
              gender: Gender.Male,
              firstName: "Sebastaian",
              otherName: "Gonzalo",
              lastName: "Salamandra",
              motherLastName: "Achuras"
            },
            organization: ["3"],
            resourceType: ResourceType.Practitioner,
            validationStatus: ValidationStatus.Temporal
          }
        }
      ],
      status: AppointmentStatus.Booked,
      resourceType: ResourceType.Appointment
    },
    encounter: {
      callData: { chatId: "243", roomList: [] },
      patient: "234",
      status: EncounterStatus.InProgress,
      resourceType: ResourceType.VideoAppointmentEncounter
    }
  },
  {
    appointment: {
      id: "61",
      creationDate: "2022-08-11T00:45:03.113Z",
      location: {
        name: "Springfield",
        resourceType: ResourceType.Location,
        status: LocationStatus.Active
      },
      patient: {
        personalData: {
          gender: Gender.Male,
          firstName: "Ivan",
          otherName: "Rodrigo",
          lastName: "Marciel",
          motherLastName: "Sabalon"
        },
        organization: "3",
        resourceType: ResourceType.Patient,
        validationStatus: ValidationStatus.Temporal
      },
      practitionerTeam: [
        {
          practitioner: {
            personalData: {
              gender: Gender.Male,
              firstName: "Sebastaian",
              otherName: "Gonzalo",
              lastName: "Salamandra",
              motherLastName: "Achuras"
            },
            organization: ["3"],
            resourceType: ResourceType.Practitioner,
            validationStatus: ValidationStatus.Temporal
          }
        }
      ],
      status: AppointmentStatus.Cancelled,
      resourceType: ResourceType.Appointment
    },
    encounter: {
      callData: { chatId: "243", roomList: [] },
      patient: "234",
      status: EncounterStatus.Cancelled,
      resourceType: ResourceType.VideoAppointmentEncounter
    }
  },
  {
    appointment: {
      id: "61",
      creationDate: "2022-08-11T00:45:03.113Z",
      location: {
        name: "Springfield",
        resourceType: ResourceType.Location,
        status: LocationStatus.Active
      },
      patient: {
        personalData: {
          gender: Gender.Male,
          firstName: "Juan",
          otherName: "Ignacion",
          lastName: "Atun",
          motherLastName: "Desmenuzado"
        },
        organization: "3",
        resourceType: ResourceType.Patient,
        validationStatus: ValidationStatus.Temporal
      },
      practitionerTeam: [
        {
          practitioner: {
            personalData: {
              gender: Gender.Male,
              firstName: "Sebastaian",
              otherName: "Gonzalo",
              lastName: "Salamandra",
              motherLastName: "Achuras"
            },
            organization: ["3"],
            resourceType: ResourceType.Practitioner,
            validationStatus: ValidationStatus.Temporal
          }
        },
        {
          practitioner: {
            personalData: {
              gender: Gender.Male,
              firstName: "Sebastaian",
              otherName: "Gonzalo",
              lastName: "Salamandra",
              motherLastName: "Achuras"
            },
            organization: ["3"],
            resourceType: ResourceType.Practitioner,
            validationStatus: ValidationStatus.Temporal
          }
        },
        {
          practitioner: {
            personalData: {
              gender: Gender.Male,
              firstName: "Sebastaian",
              otherName: "Gonzalo",
              lastName: "Salamandra",
              motherLastName: "Achuras"
            },
            organization: ["3"],
            resourceType: ResourceType.Practitioner,
            validationStatus: ValidationStatus.Temporal
          }
        }
      ],
      status: AppointmentStatus.Cancelled,
      resourceType: ResourceType.Appointment
    },
    encounter: {
      callData: { chatId: "243", roomList: [] },
      patient: "234",
      status: EncounterStatus.Cancelled,
      resourceType: ResourceType.VideoAppointmentEncounter
    }
  }
];

export const appointmentWithExtraDataMock1: AppointmentWithExtraData = {
  appointment: {
    id: "89187",
    creationDate: "2024-08-12T12:54:47.498Z",
    lastEditDate: "2024-08-14T15:05:50.000Z",
    status: AppointmentStatus.Pending,
    consultationReason: {},
    startDate: "2024-08-13T03:00:00.000Z",
    endDate: "2024-08-13T03:30:00.000Z",
    minutesDuration: 30,
    location: {
      name: "Springfield",
      resourceType: ResourceType.Location,
      status: LocationStatus.Active
    },
    practitionerTeam: [
      {
        practitioner: {
          personalData: {
            gender: Gender.Male,
            firstName: "Sebastaian",
            otherName: "Gonzalo",
            lastName: "Salamandra",
            motherLastName: "Achuras"
          },
          organization: ["3"],
          resourceType: ResourceType.Practitioner,
          validationStatus: ValidationStatus.Temporal
        }
      },
      {
        practitioner: {
          personalData: {
            gender: Gender.Male,
            firstName: "Sebastaian",
            otherName: "Gonzalo",
            lastName: "Salamandra",
            motherLastName: "Achuras"
          },
          organization: ["3"],
          resourceType: ResourceType.Practitioner,
          validationStatus: ValidationStatus.Temporal
        }
      },
      {
        practitioner: {
          personalData: {
            gender: Gender.Male,
            firstName: "Sebastaian",
            otherName: "Gonzalo",
            lastName: "Salamandra",
            motherLastName: "Achuras"
          },
          organization: ["3"],
          resourceType: ResourceType.Practitioner,
          validationStatus: ValidationStatus.Temporal
        }
      }
    ],
    patientInstruction:
      " Lorem ipsum dolor sit amet, consectetur adipiscing elit. In viverra, diam quis suscipit cursus, augue nisi sodales quam, ac semper sapien elit at arcu. Aliquam urna risus, euismod et vestibulum ac, aliquet a purus. Fusce libero purus, commodo id erat ac, laoreet lacinia nisl. Aenean viverra consectetur semper. Donec molestie dictum eleifend. Etiam vulputate imperdiet leo, a gravida quam facilisis ut. Fusce ornare faucibus ligula ut tempus.\n\nOrci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Suspendisse potenti. Nunc sit amet elit at sapien venenatis condimentum. Maecenas vulputate ipsum felis, at hendrerit lectus sollicitudin at. Sed accumsan dui nec urna dapibus, sit amet laoreet justo cursus. Curabitur ultricies, magna ut rhoncus dictum, ex risus finibus libero, sed efficitur sem dolor vitae turpis. Donec nunc libero, dignissim vel ipsum a, luctus porttitor nulla. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.\n\nEtiam nulla arcu, scelerisque nec dui a, mattis malesuada lorem. Proin elementum massa et odio dictum convallis. Ut luctus, sapien sed volutpat gravida, nisl neque eleifend quam, in consequat dui nisl non dolor. Phasellus faucibus metus sed sapien tempus aliquam. Sed faucibus dignissim massa ut pharetra. Nam id eros sit amet dolor aliquam feugiat et non urna. Nullam aliquam egestas ipsum, at ultricies eros dignissim et. Pellentesque malesuada eleifend lorem id lobortis. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Ut fringilla quis ex convallis molestie. Nunc ut convallis ex, vel dignissim neque. Donec aliquam dapibus quam, vitae feugiat velit finibus et. In at tellus erat. Nullam et ornare metus. Curabitur sed neque eget elit tempus suscipit. ",
    patientAccessInfo: {
      hash: "_60g0RXnWSkuxaVH4rcoPg",
      link: "https://development.patient.nexup.com.ar/appointment/_60g0RXnWSkuxaVH4rcoPg/access",
      addToCalendarUrl:
        "https://calendar.google.com/calendar/r/eventedit?action\u003dTEMPLATE\u0026text\u003dTurno%20con%20nullen%20Institucion%20Mixta\u0026dates\u003d20240813T030000Z%2F20240813T033000Z\u0026details\u003dPara%20ver%20el%20detalle%20de%20tu%20turno%20ingresa%20aqu%C3%AD%3A%20https%3A%2F%2Fdevelopment.patient.nexup.com.ar%2Fappointment%2F_60g0RXnWSkuxaVH4rcoPg%2Faccess\u0026location\u003dpadre%20acevedo12%201777%20casa%20del%20fondo\u0026trp\u003dtrue"
    },
    plan: "test plan",
    patient: {
      id: "88968",
      federationId: "72c5a773-a0ab-4c7b-b80e-fcf0f16304ff",
      personalData: {
        birthDate: getCurrentDateAsString(),
        firstName: "Paciente",
        otherName: "",
        lastName: " con cobertura",
        gender: Gender.Male,
        identifierList: [
          {
            system:
              "http://platform.lab-a.com.ar/fhir/StructureDefinition/person-nexup-identifier",
            value: "72c5a773-a0ab-4c7b-b80e-fcf0f16304ff",
            files: [],
            property: [],
            resourceType: ResourceType.Identifier
          },
          {
            system: "http://www.renaper.gob.ar/dni",
            value: "564757858",
            files: [],
            property: [],
            resourceType: ResourceType.Identifier
          }
        ]
      },
      contactData: {
        phoneList: [
          {
            resourceType: ResourceType.Phone,
            role: ContactPointRole.NotificationPhone,
            type: ContactPointType.Work,
            value: "+1111111111"
          }
        ],
        emailList: [
          {
            resourceType: ResourceType.Email,
            role: ContactPointRole.NotificationEmail,
            type: ContactPointType.Work,
            value: "juanromanriquelme@hotmail.com"
          }
        ],
        addressList: []
      },
      validationStatus: ValidationStatus.Temporal,
      organization: "10",
      patientData: {
        healthcarePayer: {
          provider: {
            id: "87628",
            name: "test conv",
            active: true,
            type: OrganizationType.Payer,
            medicalRequestExpirationTimeList: [],
            medicalRequestExpirationWarningTimeList: [],
            creationDate: "2024-06-07T21:27:42.465Z",
            partOf: "1955",
            reportConfig: [],
            emailConfig: [],
            phoneConfig: [],
            public: false,
            domainUrlList: [],
            ipWhitelist: [],
            dashboardConfig: [],
            identifier: [],
            email: [],
            phone: [],
            address: [],
            speciality: [],
            interest: [],
            lastEditDate: "2024-06-07T21:27:41.000Z",
            invitationBlacklist: [],
            resourceType: ResourceType.Organization
          },
          credentialNumber: "456564565646",
          credentialFiles: []
        },
        managedBy: []
      },
      user: "14c0d087-28dc-43a0-8fc6-dabfe88c76ed",
      roleList: [{ organization: "10", role: "paciente autogenerado" }],
      active: true,
      creationDate: "2024-08-01T19:11:16.697Z",
      resourceType: ResourceType.Patient
    },
    notificationChannelList: [
      {
        contactPoint: {
          resourceType: ResourceType.Phone,
          role: ContactPointRole.NotificationPhone,
          type: ContactPointType.Work,
          value: "+1111111111"
        }
      },
      {
        contactPoint: {
          resourceType: ResourceType.Email,
          role: ContactPointRole.NotificationEmail,
          type: ContactPointType.Work,
          value: "juanromanriquelme@hotmail.com"
        }
      }
    ],
    overbooked: false,
    identifier: [],
    schedule: {
      id: "88274",
      confirmationWindow: { start: 42, end: 24 },
      cancellationWindow: { start: 42, end: 24 },
      identifier: [],
      creationDate: "2024-07-03T14:51:05.786Z",
      lastEditDate: "2024-08-05T16:10:01.000Z",
      name: "78rerd",
      type: KnownScheduleType.Presential,
      speciality: ["hola"],
      performer: [],
      planningHorizon: {
        start: "2024-07-03T03:00:00.000Z",
        end: "2025-07-03T03:00:00.000Z"
      },
      active: true,
      availability: {
        slotDuration: 30,
        slotDurationList: [30],
        overbookingLimit: 3,
        availableTime: [
          {
            allDay: false,
            startTime: "00:30:00",
            endTime: "01:30:00",
            daysOfWeek: [Day.Monday]
          },
          {
            allDay: false,
            startTime: "00:00:00",
            endTime: "01:00:00",
            daysOfWeek: [Day.Tuesday]
          }
        ],
        notAvailableTime: []
      },
      originalPractitioner: "260",
      practitioner: "260",
      organization: "10",
      notificationConfig: {
        patient: {
          codeList: ["Create", "EditPatientData", "Cancel"],
          notifyPhone: true,
          notifyEmail: true,
          active: true,
          reminderList: []
        },
        practitioner: {
          codeList: [],
          notifyPhone: false,
          notifyEmail: false,
          active: false,
          reminderList: []
        }
      },
      product: [],
      resourceType: ResourceType.Schedule
    },
    confirmedDate: "2024-08-12T12:54:47.000Z",
    speciality: ["TestSpeciality"],
    attachment: [],
    tagList: [],
    notificationConfig: {
      patient: {
        codeList: ["Create", "EditPatientData", "Cancel"],
        notifyPhone: true,
        notifyEmail: true,
        active: true,
        reminderList: []
      },
      practitioner: {
        codeList: [],
        notifyPhone: false,
        notifyEmail: false,
        active: false,
        reminderList: []
      }
    },
    product: [],
    payer: {
      id: "87628",
      name: "test conv",
      active: true,
      type: OrganizationType.Payer,
      medicalRequestExpirationTimeList: [],
      medicalRequestExpirationWarningTimeList: [],
      creationDate: "2024-06-07T21:27:42.465Z",
      partOf: "1955",
      reportConfig: [],
      emailConfig: [],
      phoneConfig: [],
      public: false,
      domainUrlList: [],
      ipWhitelist: [],
      dashboardConfig: [],
      identifier: [],
      email: [],
      phone: [],
      address: [],
      speciality: [],
      interest: [],
      lastEditDate: "2024-06-07T21:27:41.000Z",
      invitationBlacklist: [],
      resourceType: ResourceType.Organization
    },
    type: KnownScheduleType.VideoChat,
    resourceType: ResourceType.Appointment
  }
};
