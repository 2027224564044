import {
  FCC,
  getClassName,
  IconC,
  TextVariant,
  ThemeStyleColorVariant
} from "@laba/react-common";
import { NexupIconButton } from "components/buttons/IconButton/IconButton";
import { RemoveIcon } from "components/icons";
import { TypeInvertedVariant } from "components/utils/getGenericButtonColors";
import React from "react";
import { isEmpty } from "lodash-es";
import { Noop, notUndefined } from "@laba/ts-common";
import { OnClickUrlAction, UrlLink } from "components/link";
import { useGenericFieldWrapperStyles } from "./useGenericFieldWrapperStyles";
import { formatFieldTitle } from "./utils";

export enum FieldWrapperTitleLinkPosition {
  Top = "Top",
  Bottom = "Bottom"
}

export interface GenericFieldWrapperProps {
  title?: string;
  subTitle?: string;
  className?: string;
  titleVariant?: TextVariant;
  titleColorVariant?: ThemeStyleColorVariant;
  subTitleVariant?: TextVariant;
  subTitleColorVariant?: ThemeStyleColorVariant;
  gap?: number;
  isMobile?: boolean;

  withIconButton?: boolean;
  icon?: IconC;
  iconTypeInvertedVariant?: TypeInvertedVariant;
  onIconButtonClick?: Noop;
  iconButtonTitle?: string;
  disableIconButton?: boolean;

  showEmptyTitle?: boolean;
  hide?: boolean;

  headerTitleLinkText?: string;
  headerTitleLinkOnClick?: Noop;
  disabledHeaderTitleLink?: boolean;
  headerTitleLinkPosition?: FieldWrapperTitleLinkPosition;
  headerTitleLinkType?: OnClickUrlAction;
  headerTitleLink?: string;

  withTitle?: boolean;
  required?: boolean;
  fullWidth?: boolean;
}

export const GenericFieldWrapper: FCC<GenericFieldWrapperProps> = ({
  title,
  subTitle,
  children,
  className,
  titleVariant,
  titleColorVariant = ThemeStyleColorVariant.GrayDark,
  subTitleVariant,
  subTitleColorVariant = ThemeStyleColorVariant.GrayLight,
  gap,
  isMobile,
  withIconButton = false,
  icon = RemoveIcon,
  iconTypeInvertedVariant = TypeInvertedVariant.Outlined,
  onIconButtonClick,
  iconButtonTitle,
  disableIconButton,
  showEmptyTitle = false,
  hide = false,
  withTitle = true,
  headerTitleLinkText,
  headerTitleLinkOnClick,
  disabledHeaderTitleLink = false,
  headerTitleLinkPosition = FieldWrapperTitleLinkPosition.Top,
  headerTitleLink,
  headerTitleLinkType = OnClickUrlAction.CustomClick,
  required,
  fullWidth = false
}) => {
  const classes = useGenericFieldWrapperStyles({
    titleVariant,
    titleColorVariant,
    subTitleVariant: subTitleVariant ?? titleVariant,
    subTitleColorVariant,
    gap,
    isMobile,
    fullWidth
  });

  const showHeaderLinkText =
    notUndefined(headerTitleLinkText) &&
    ((headerTitleLinkType === OnClickUrlAction.CustomClick &&
      notUndefined(headerTitleLinkOnClick)) ||
      (headerTitleLinkType === OnClickUrlAction.Redirect &&
        notUndefined(headerTitleLink)));

  const formattedTitle = formatFieldTitle(title, required);
  // TODO HIS-14946 Evitar tener que usar el caracter especial "U+200E"
  const fixedTitle = !isEmpty(formattedTitle)
    ? formattedTitle
    : showEmptyTitle
    ? "‎"
    : formattedTitle;

  const UrlLinkComponent = showHeaderLinkText && (
    <UrlLink
      displayText={headerTitleLinkText}
      onClickUrlAction={headerTitleLinkType}
      onClick={headerTitleLinkOnClick}
      className={classes.urlLink}
      disabled={disabledHeaderTitleLink}
      url={headerTitleLink}
    />
  );

  const isUrlLinkTop =
    headerTitleLinkPosition === FieldWrapperTitleLinkPosition.Top;
  return !hide ? (
    withTitle ? (
      <div className={getClassName(classes.root, className)}>
        {(fixedTitle || subTitle || withIconButton) && (
          <div className={classes.fieldHeading}>
            <div className={classes.titleContainer}>
              {fixedTitle && <p className={classes.title}>{fixedTitle}</p>}
              {subTitle && <p className={classes.subTitle}>{subTitle}</p>}
            </div>
            {withIconButton && (
              <NexupIconButton
                Icon={icon}
                type={iconTypeInvertedVariant}
                onClick={onIconButtonClick}
                title={iconButtonTitle}
                disabled={disableIconButton}
              />
            )}
            {isUrlLinkTop && UrlLinkComponent}
          </div>
        )}
        {children}
        {!isUrlLinkTop && UrlLinkComponent}
      </div>
    ) : (
      <div className={className}>{children}</div>
    )
  ) : null;
};
