import React from "react";
import {
  FCC,
  getWhatsappPhoneChatLink,
  TextVariant,
  ThemeStyleColorVariant
} from "@laba/react-common";
import { LinkFieldDetail } from "components/linkFieldDetail/LinkFieldDetail";

export interface OpenWhatsappChatProps {
  title?: string;
  phoneText?: string;
  gap?: number;
  className?: string;
  hide?: boolean;
  titleVariant?: TextVariant;
  titleColorVariant?: ThemeStyleColorVariant;
}

export const OpenWhatsappChat: FCC<OpenWhatsappChatProps> = ({
  phoneText,
  ...rest
}) => {
  const whatsappUrl = phoneText
    ? getWhatsappPhoneChatLink(phoneText)
    : undefined;

  return (
    <LinkFieldDetail displayText={phoneText} url={whatsappUrl} {...rest} />
  );
};
